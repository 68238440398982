import { format } from 'date-fns';

export const formatDateToDisplay = (
  stringDate,
  dateFormat = 'do MMMM, yyyy'
) => {
  if (!stringDate) {
    return null;
  }
  return format(new Date(stringDate), dateFormat);
};

export const formatDateTimeToDisplay = (
  stringDate,
  dateFormat = 'do MMMM, yyyy HH:mm'
) => {
  if (!stringDate) {
    return null;
  }
  return format(new Date(stringDate), dateFormat);
};
