<template>
  <content-layout
    :breadcrumbs-items="breadcrumbsItems"
    :toolbar-enabled="false"
    toolbar-flat
    :search-enabled="false"
    search-bar-in-toolbar
    :search-text.sync="searchText"
    :loading="loading"
  >
    <template #toolbar>
      <cz-button
        :title="$t('customer.workflowManagementPage.createWorkflowButtonTitle')"
        color="primary"
        :icon-src="mdiPlusCircleOutline"
      />
    </template>
    <div class="pa-6">
      <v-card
        width="100%"
        v-for="item in accountWorkflows"
        :key="item._id"
        :min-height="70"
        class="mb-4"
        :hover="false"
      >
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <v-row align="center" class="flex-grow-1">
              <v-col :lg="2">
                <cz-form-field
                  :label="$t('customer.workflowManagementPage.workflowName')"
                >
                  <div class="text-body-1">{{ item.name }}</div>
                </cz-form-field>
              </v-col>
              <v-col :lg="2">
                <cz-form-field
                  :label="$t('customer.workflowManagementPage.workflowType')"
                >
                  <div class="text-body-1">{{ item.typeText }}</div>
                </cz-form-field>
              </v-col>
              <v-col :lg="2">
                <cz-form-field
                  :label="$t('customer.workflowManagementPage.updateDate')"
                >
                  <div class="text-body-1">
                    {{ formatDateTimeToDisplay(item.updatedAt) }}
                  </div>
                </cz-form-field>
              </v-col>
              <v-col :lg="2">
                <cz-chip
                  circle-edges
                  color="accent"
                  :text="
                    $t('customer.workflowManagementPage.stepsCount', [
                      item.stepsCount
                    ])
                  "
                />
              </v-col>
            </v-row>
            <!-- <cz-icon :src="mdiChevronLeft" :size="32" /> -->
          </div>
        </v-card-text>
      </v-card>
    </div>
  </content-layout>
</template>

<script>
import { CzFormField, CzChip, CzIcon, CzButton } from '@/components';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import { formatDateTimeToDisplay } from '@/shared/services/dates.service';
import { mdiChevronLeft, mdiPlusCircleOutline } from '@mdi/js';
export default {
  name: 'WorkflowsManagementPage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    CzFormField,
    CzChip,
    CzIcon,
    CzButton
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapFields('customer', ['accountWorkflows']),
    ...mapFields('auth', ['accountId']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.workflowsManagementCardTitle'),
          disabled: true,
          exact: true
        }
      ];
    }
  },
  data() {
    return {
      mdiPlusCircleOutline,
      mdiChevronLeft,
      loading: false,
      searchText: ''
    };
  },
  methods: {
    formatDateTimeToDisplay,
    ...mapActions('customer', ['loadAccountWorkflows']),
    async loadData() {
      try {
        this.loading = true;
        await this.loadAccountWorkflows(this.accountId);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
